<template>
  <div class="logout">
    <img
      src="@/assets/img/logout/dimg.jpg"
      class="img-fluid"
      alt="logout placeholder"
      v-if="
        isUADesktop || (isUAMobile && !isPotrait) || (isUATablet && !isPotrait)
      "
    />
    <img
      src="@/assets/img/logout/mimg.jpg"
      class="img-fluid"
      alt="logout placeholder"
      v-if="(isUAMobile && isPotrait) || (isUATablet && isPotrait)"
    />
  </div>
</template>

<script>
import UAParser from "ua-parser-js";
import $ from "jquery";

export default {
  name: "logout",
  data() {
    return {
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
      isPotrait: false,
    };
  },
  methods: {
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
  },
  mounted() {
    $(window).bind("orientationchange", function (event) {
      location.reload(true);
    });
    let parser = new UAParser();

    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }
    this.getOrientation();
  },
};
</script>

<style lang="scss" scoped>
.logout {
  min-height: 100vh;
  background-color: #bbbbbb;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
  }
}
</style>
